const defaultDealData = {
  igst: 0,
  igstvalue: 0,
  selectedInvestorId: null,
  selectInvestorModal: false,
  group_list: [],
  deal_id: "",
  investor_id: "",
  interested_id: "",
  deal_name: "",
  created_at: "",
  deal_description: "",
  isPrivate: false,
  isFunded: false,
  isBlank: false,
  tags: [],
  logo: "",
  youtube_url: "",
  dealenddays: 0,
  kycstatus: false,
  bankstatus: false,
  commaAmount: 0,
  amount: 0,
  minamount: 0,
  captable_threshold_amount: 0,
  maxamount: 0,
  captable_multiple_amount: 0,
  investmentmodal: false,
  confirmmodalstatus: false,
  deduct: false,
  agree: "",
  isInvested: false,
  name: "",
  email: "",
  mobile: "",
  conveniencefees: 100,
  gst: 0,
  amountplusgst: 0,
  processingfees: 0.0,
  totalamount: 0.0,
  tdsstatus: false,
  legalfee: 0.0,
  legalplusprocessing: 0.0,
  label: "",
  percentage: 0,
  check_membership_type: "",
  member_detail: "",
  tdsdeductedamount: 0,
  order_token: "",
  pdffile: "",
  pitch_files: "",
  walletMoney: 0,
  gstValue: 0,
  walletDeductionMoney: 0,
  checkWallet: false,
  percentage_raised: 0,
  documents: [],
  button_status: true,
  show_data: "none",
  button_show_status: true,
  amount_error: "",
  amount_error_status: false,
  multiples_of: 0,
  founder_is_investor: 0,
  user_type: "",
  coming_soon_days: "",
  deal_regular_end_date: "",
  is_deal_visible: true,
  deal_regular_end_date_status: 0,
  invest_amt: "",
  escrowact: "",
  escrow_account_ifsc: "",
  agreeCheck: false,
  Convenience: "",
  gstBusinessName: "",
  gstNo: "",
  gstmodal: false,
  deal_service: "",
  images: [],
  current: "",
};

export { defaultDealData };
